import React from 'react'
import'./Porfolio.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';


function  Portfolio(props) {

    const [portfolio, setPortfolio] = useState(null)
    const [collaborators, setCollaborators] = useState(null)
    const [artwork, setArtwork] = useState(null)
    const [portfolios, setPorfolios] = useState(null)
    const [profileArt, setprofileArt] = useState(null);
    const [portfoliolength, setPortfolioLength] = useState(null)
    const id  = useParams();

    //url
    const [url, setUrl] = useState(process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000' : 'https://cifeapi.theflyu2.com');

    // show art
    const [showArt, setArtShow] = useState(false); 
    const [artImage, setArtImage] = useState(null)
    const [title, setTitle] = useState(null) 
    const [year, setYear] = useState(null)
    const [description, setDescription] = useState(null)    
    const handleArtClose = () => setArtShow(false);
    const handleArtShow = (event) => {
        setYear(event.target.name)
        async function fetchProfileArt() {
            const response = await fetch(`${url}/visual_arts/get_profileart/${portfolio.profile}`);
            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            const profileArt = await response.json();
            setprofileArt(profileArt);
            profileArt.forEach(element => {
                if(element.portfolio == event.target.id){
                    setArtImage(element.image)
                    setTitle(element.title)
                    setDescription(element.description)
                    setArtShow(true);
                }
            });
            }
            fetchProfileArt().catch(error => {
            console.log(error.message); // 'An error has occurred: 404'
        });
    }
   
    useEffect(() => {        
        async function fetchPorfolio() {
            const response = await fetch(`${url}/visual_arts/portfolio/${id.id}`);
            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            const portfolio = await response.json();
            // nested request
            setPortfolio(portfolio);
                async function fetchPortfolios() {
                    const response = await fetch(`${url}/visual_arts/artist_portfolio/${portfolio.profile}`);
                    if (!response.ok) {
                        const message = `An error has occured: ${response.status}`;
                        throw new Error(message);
                    }
                    const portfolios = await response.json();
                    setPorfolios(portfolios);
                    let tempoArray = []
                    for (let index = 0; index < portfolios.length; index++) {
                        if(portfolios[index].id != id.id){
                            tempoArray.push(portfolios[index])
                        }            
                    }

                    // dont render other portfolios if not present
                    if(tempoArray.length==0){
                        setPortfolioLength(false)
                    }else{
                        setPortfolioLength(true)
                    }
                    return portfolios
                }
                    fetchPortfolios().catch(error => {
                    console.log(error.message); // 'An error has occurred: 404'
                });     
            }
            fetchPorfolio().catch(error => {
            console.log(error.message); // 'An error has occurred: 404'

        });
        async function fetchCollaborators() {
            const response = await fetch(`${url}/visual_arts/collaborators/${id.id}`);
            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            const collaborators = await response.json();
            setCollaborators(collaborators);
            return collaborators;
            }
            fetchCollaborators().catch(error => {
            console.log(error.message); // 'An error has occurred: 404'
        });
        async function fetchArt() {
            const response = await fetch(`${url}/visual_arts/portfolio_art/${id.id}`);
            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            const art = await response.json();
            setArtwork(art);
            return art;
            }
            fetchArt().catch(error => {
            console.log(error.message); // 'An error has occurred: 404'
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const renderCollaborators = () => {
        return (
            (collaborators || []).map((element, index)=>                       
            <Row className="m-0 p-2" key={index}>
                <Col xs={12} md={3} className="collaborator-image p-2 p-md-0">
                    <img className="rounded-circle border border-0" src={element.image} alt="collaborator"/>
                </Col>
                <Col xs={12} md={9}>
                    <Row className="m-0 text-three">
                        <Col xs={12}>{element.social_url}</Col>
                        <Col xs={12} className="custom-grey mt-1">{element.contribution}</Col>
                    </Row>
                </Col>
            </Row>
            )
        )
    }

    const renderArt = () => {

        return (
            (artwork || []).map((element, index)=>
                <div className="ps-4 pe-4 ps-md-0 pe-md-0" key={index}>                 
                    <Col xs={12} className="portfolio-art">
                        <img src={element.image} alt="art" />
                    </Col>
                    <Col xs={12} className="text-three fw-bold text-black text-center p-2">{element.title}</Col>
                    <Col xs={12} className="text-three text-black text-center p-2">{element.description}</Col>
                </div>      
            )
        )
    }

    const renderPortfolios= () => {

        let tempoArray = []

        for (let index = 0; index < portfolios.length; index++) {
            if(portfolios[index].id != id.id){
                tempoArray.push(portfolios[index])
            }            
        }

        return (
            (tempoArray || []).map((element, index)=>                             
                <Col href="/" xs={4} className="portfolio-display p-1 p-md-3" key={index}>
                    {element.collection ?<div>
                    <a href={`/visual/portfolio/${element.id}`} variant="none"><img src={element.image} alt="art" /></a>
                    <div className='porfolio-tag d-inline rounded-4 pt-1 pb-1 p-1 p-md-2'>
                        {element.collection ? <FontAwesomeIcon className='pe-2 ps-2 btn hover text-secondary' icon={faLayerGroup} />
                        : <div></div>}                        
                    </div></div>  :<div> 
                    <div onClick={handleArtShow} variant="none"><img src={element.image} alt="art" id={element.id} name={element.year}/></div>
                    <div className='porfolio-tag d-inline rounded-4 pt-1 pb-1 p-1 p-md-2'>
                        {element.collection ? <FontAwesomeIcon className='pe-2 ps-2 btn hover text-secondary' icon={faLayerGroup} />
                        : <div></div>}                        
                    </div></div> 
                    }
                </Col>            
            )
        )
    }

    const singleArt = () => {
        return (
            <Row className="m-0 p-3 fade-in-image">
                <Row className="m-0">
                    <Col className="d-none d-md-block col-md-2"></Col>
                    <Col xs={12} md={8} className="modal-single-art text-center"><img src={artImage} alt="artwork"/></Col>
                    <Col className="d-none d-md-block col-md-2"></Col>
                </Row>
                <Row className="m-0">
                    <Col xs={12} md={2}>
                        <Row className="m-0 text-start h8 public-san">
                            <Col xs={12} className="text-secondary ">Title</Col>
                            <Col xs={12} className="text-black ">{title}</Col>
                            <Col xs={12} className="text-secondary mt-3">Year</Col>
                            <Col xs={12} className="text-black mb-3 md-md-0">{year}</Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={10}>
                        <Row className="m-0 h8 public-san">
                            <Col xs={12} className="text-secondary text-start">Description</Col>
                            <Col xs={12} className="text-black text-start">{description}</Col>
                        </Row>
                    </Col>
                </Row>
            </Row>
        )
    } 
   
    return (
        <Row className='m-0'>
            <Col className="d-none d-md-block col-md-1 col-lg-1 col-xl-2"></Col>
            <Col className="col-12 col-md-10 col-lg-10 col-xl-8 p-0">
                <a href={portfolio ? `/profile/visual/${portfolio.profile}` : '/'} className='text-back text-start h8 d-block text-decoration-none hover ps-3 ps-md-0'>Back</a>
                <Row className='m-0'>
                    <Col className="text-start h3 text-black ps-0 mt-3 fw-bold text-small" xs={12} md={6}>{portfolio ? portfolio.title: <div></div>}</Col>
                    <Col className="text-end text-small" xs={12} md={6}><div className="d-block h8">Year</div><div className="d-block">{portfolio ? portfolio.year: <div></div>}</div></Col>
                    <Col className="text-start h5 text-black ps-0 mt-3 mb-3 mb-md-5 text-small"xs={12}>{portfolio ? portfolio.short_description: <div></div>}</Col>
                    <Col xs={12} md={5} className="p-0">
                        <div className="portfolio-main-image"><img src={portfolio ? portfolio.image : <div></div>} alt="porfolio"/></div>
                    </Col>
                    <Col xs={12} md={7} className="justify-text font-two text-black p-5">
                        {portfolio ? portfolio.description : <div></div>}
                        {collaborators ? 
                        <div className="mt-3 mt-md-5 collaborator-panel">
                            <div className="text-black fw-bold text-one">Collaborators:</div>
                            {renderCollaborators()}
                        </div> : 
                        <div></div>}
                    </Col>
                    <Row className="m-0 mt-2 mt-md-5">
                        <Col className="d-none d-sm-block col-sm-3"></Col>
                        <Col className="col-12 col-sm-6">
                            <Row className="m-0">
                                {renderArt()}
                            </Row>
                        </Col>
                        <Col className="d-none d-sm-block col-sm-3"></Col>
                    </Row>
                    {portfoliolength}
                    {portfoliolength ? 
                    <Row className="m-0 d-flex justify-content-center mt-2 mt-md-5">
                        <Col xs={12} className="text-start h3 fw-bold text-four mb-2 mb-md-5">Other portfolio projects</Col>
                        {renderPortfolios()}
                    </Row> 
                    : <div></div>
                    }                   
                </Row>
            </Col>
            <Col className="d-none d-md-block col-md-1 col-lg-1 col-xl-2"></Col>

            <Modal show={showArt} onHide={handleArtClose} className="art-modal" size="lg">
                <Modal.Header closeButton className="border border-0 rounded-0"></Modal.Header>
                <Modal.Body className="p-0 rounded-0">
                    {singleArt()}
                </Modal.Body>
            </Modal> 
        </Row>
    );
}

export default Portfolio;