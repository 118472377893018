import React from 'react'
import './Profile.css'
import { useParams } from 'react-router-dom';
import { useState, useEffect } from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Carousel from 'react-bootstrap/Carousel'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagram, faTwitterSquare} from "@fortawesome/free-brands-svg-icons"
import { faLayerGroup, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import coffee from '../media/coffee.png'
import contribution from '../media/contribution.png'
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import ShopForm from './Shop';
import img_placeholder from '../media/avatar.png' 


function  Visual(props) {
    const id  = useParams();
    const [artist, setArtists] = useState(null);
    const [profileArt, setprofileArt] = useState(null);
    const [artistPorfolio, setArtistPortfolio] = useState(null);
    const [shop, setShop] = useState(null);
    const [about, setAbout] = useState(null);
    const [support, setSupport] = useState([1, 2])
    const [press, setPress] = useState(null)


    // shop Modal
    const [show, setShow] = useState(false);
    const [item, setItem] = useState(false);
    const handleClose = () => setShow(false);

    // show art
    const [showArt, setArtShow] = useState(false); 
    const [artImage, setArtImage] = useState(null)
    const [title, setTitle] = useState(null) 
    const [year, setYear] = useState(null)
    const [description, setDescription] = useState(null)    
    const handleArtClose = () => setArtShow(false);
    const handleArtShow = (event) => {
        setYear(event.target.name)
        console.log(event.target.id)
        async function fetchProfileArt() {
            const response = await fetch(`${url}/visual_arts/portfolio_art/${event.target.id}`);
            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }{  
                const singleId = await response.json();
                const response_ = await fetch(`${url}/visual_arts/get_portfolioart/${singleId[0].id}`);
                if (!response_.ok) {
                    const message = `An error has occured: ${response_.status}`;
                    throw new Error(message);
                }
                const profileArt_ = await response_.json(); 
           
                setArtImage(profileArt_.image)
                setTitle(profileArt_.title)
                setDescription(profileArt_.description)
                setArtShow(true);    
              
                }
            }
            fetchProfileArt().catch(error => {
            console.log(error.message); // 'An error has occurred: 404'
        });
    }

    //flash messages
    const [message, setMessage] = useState(null)

    //url
    const [url, setUrl] = useState(process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000' : 'https://cifeapi.theflyu2.com');

    // handle page layout
    const handleMessage = (message) => {
        setMessage(message)
        setTimeout(() => {
            setMessage(null)
        }, 3000);
    }

    useEffect(() => { 
        async function fetchArtist() {
            const response = await fetch(`${url}/profiles/${id.id}`);
            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            const artist = await response.json();
            setArtists(artist);
            return artist;
            }
            fetchArtist().catch(error => {
            console.log(error.message); // 'An error has occurred: 404'
        }); 
        async function fetchArt() {
            const response = await fetch(`${url}/visual_arts/get_profileart/${id.id}`);
            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            const profileArt = await response.json();
            // limit profile arts show on slide show to 5
            setprofileArt(profileArt.splice(0,5));
            return profileArt;
            }
            fetchArt().catch(error => {
            console.log(error.message); // 'An error has occurred: 404'
        });
        async function fetchPortfolio() {
            const response = await fetch(`${url}/visual_arts/artist_portfolio/${id.id}`);
            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            const portfolio = await response.json();
            setArtistPortfolio(portfolio);
            return portfolio;
            }
            fetchPortfolio().catch(error => {
            console.log(error.message); // 'An error has occurred: 404'
        });
        async function fetchShop() {
            const response = await fetch(`${url}/profiles/selling/${id.id}`);
            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            const shop = await response.json();
            setShop(shop);
            return shop;
            }
            fetchShop().catch(error => {
            console.log(error.message); // 'An error has occurred: 404'
        });
        async function fetchAbout() {
            const response = await fetch(`${url}/profiles/my_about/${id.id}`);
            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            const about = await response.json();
            if(about.profile){
                setAbout(about);
                return about;
            }            
            }
            fetchAbout().catch(error => {
            console.log(error.message); // 'An error has occurred: 404'
        });
        async function fetchPress() {
            const response = await fetch(`${url}/profiles/get_press/${id.id}`);
            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            const press = await response.json();
            setPress(press);
            return shop;
            }
            fetchPress().catch(error => {
            console.log(error.message); // 'An error has occurred: 404'
        });
        async function fetchSupport() {
            const response = await fetch(`${url}/profiles/get_support/${id.id}`);
            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            const support = await response.json();
            setSupport(support);
            return support;
            }
            fetchSupport().catch(error => {
            console.log(error.message); // 'An error has occurred: 404'
        });                
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderImages = () => {
        return (
            (profileArt || []).map((element, index)=>
                <Carousel.Item key={index}>
                    <img
                    className="img-fluid"
                    src={element.image}
                    alt="First slide"
                    />
                </Carousel.Item>   
            )
        )
    }

    const splitTags = (tags) => {
        const tagList = tags.split(",")
        return (
            (tagList|| []).map((element, index)=>                       
            <div className='text-start tags me-1 ps-2 pe-2 rounded-4 p-1 d-inline text-three' key={index}>{element}</div>
        )
        )
    }

    const renderPorfolio = () => {
        return (
            (artistPorfolio || []).map((element, index)=>                    
                <Col className="artist-portfolio p-2 p-md-4" xs={6} sm={6} md={6} lg={6} xl={4} key={index}>
                    {element.collection ? <a href={`/visual/portfolio/${element.id}`} className='portfolio-container rounded-2'>
                        <img className="rounded-2" src={element.image} alt='art' /> </a> : 
                        <div className='portfolio-container rounded-2' onClick={handleArtShow}>
                            <img className="rounded-2" src={element.image} alt='art' id={element.id} name={element.year}/> 
                        </div>
                    }                    
                    <div className='collection-tag d-inline rounded-4 pt-1 pb-1 p-1 p-md-2'>
                        {element.collection ? <FontAwesomeIcon className='pe-2 ps-2 btn hover text-secondary' icon={faLayerGroup} />
                        : <div></div>}                        
                    </div>
                </Col>        
            )
        )
    }

    const handleShopModal = (event) => {
        setShow(true)
        setItem(event.target.id)
    }

    const renderShop = () => {
        return (
            (shop || []).map((element, index)=>                       
                <div className="p-2 portfolio_item mb-1" key={index}>
                    <img className="rounded-3" src={element.image} alt='art' onClick={handleShopModal} id={element.id} />
                    <div className="text-start h6 mt-3">{element.description}</div>
                    <Col className="text-start custom-grey mt-3 mt-md-4" xs={12}>
                        <div className='text-start tags me-1 ps-2 pe-2 rounded-4 d-inline text-three'>Portrait</div>
                        <div className='text-start tags me-1 ps-2 pe-2 rounded-4 d-inline text-three'>Commissions</div>
                    </Col>
                </div>
            )
        )
    } 
    
    const renderCard = () => {
        return (
            <div className='support-cards'>
            {support.support_one ?       
            <Card style={{ width: '18rem' }} className="shadow support-card m-3 border border-0 rounded-0 mb-5">
                <Card.Img variant="top" src={coffee} />
                <Card.Body>
                    <Card.Title className='h6 text-start fw-bold text-four'>Buy me a <span className='text-custom-1'>coffee</span></Card.Title>
                    <Card.Text className="text-start text-body">
                        One-Time Contribution
                        Buy me a coffee
                        Support my work by sponsoring a cup of coffee, with a one-time contribution of Rs150.
                        Buy me coffee
                    </Card.Text>
                    <Button variant="dark" size='sm' href={support.support_one_url} target="_blank">Buy me a coffee</Button>
                </Card.Body>
            </Card>
            : <div></div> }
            {support.support_two ?
            <Card style={{ width: '18rem' }} className="shadow support-card m-3 border border-0 rounded-0">
                <Card.Img variant="top" src={contribution} />
                <Card.Body>
                    <Card.Title className='h6 text-start fw-bold text-four'>Become a <span className='text-custom-1'>Supporter</span></Card.Title>
                    <Card.Text className="text-start text-body custom-grey">
                        Recurring Contribution
                        Join my Supporters Club
                        With as little as $3/month, show your support for my work and support my creative journey.
                        Become a Supporter
                    </Card.Text>
                    <Button variant="dark text-end" size='sm' href={support.support_two_url}  target="_blank">Become a Supporter</Button>
                </Card.Body>
            </Card>
            : <div></div> }
            </div>     
      
        )
    }


    const renderPress = () => {
        return (
            (press || []).map((element, index)=>                       
            <Card style={{ width: '18rem' }} className="press-card m-2 shadow mb-5" key={index}>
                <Card.Img variant="top" src={element.image} />
                <Card.Body>
                <Card.Title className='text-black h6 text-start'>{element.description}</Card.Title>
                <Card.Text>
                    <div className='h8 text-start text-four'>published in: <span className='text-bold text-black '>{element.published_in}</span></div>
                    <div className='text-start'>
                        <a className='text-decoration-none text-dark hover' variant="link rounded-0 text-start" href={element.press_url} target="_blank" size="small">{element.press_url}</a>
                    </div> 
                </Card.Text>                   
                </Card.Body>
            </Card>
            )
        )
    }
    

    const handleImageLeft = () => {
        var container = document.getElementById('container');
        container.scrollLeft -= 10;
    }

    const handleImageRight = () => {
        var container = document.getElementById('container');
        container.scrollLeft += 10;
    }

    const handleImageLeftCard = () => {
        var container = document.getElementById('container_2');
        container.scrollLeft -= 10;
    }

    const handleImageRightCard = () => {
        var container = document.getElementById('container_2');
        container.scrollLeft += 10;
    }

    const handleImageLeftPress = () => {
        var container = document.getElementById('container_3');
        container.scrollLeft -= 10;
    }

    const handleImageRightPress = () => {
        var container = document.getElementById('container_3');
        container.scrollLeft += 10;
    }
    
    const setSelectedModal = (message) => {
       setShow(false) 
       handleMessage('We have contacted the artist!')              
    }

    const singleArt = () => {
        return (
            <Row className="m-0 p-3 fade-in-image">
                <Row className="m-0">
                    <Col className="d-none d-md-block col-md-2"></Col>
                    <Col xs={12} md={8} className="modal-single-art text-center"><img src={artImage} alt="artwork"/></Col>
                    <Col className="d-none d-md-block col-md-2"></Col>
                </Row>
                <Row className="m-0">
                    <Col xs={12} md={2}>
                        <Row className="m-0 text-start h8 public-san">
                            <Col xs={12} className="text-secondary ">Title</Col>
                            <Col xs={12} className="text-black ">{title}</Col>
                            <Col xs={12} className="text-secondary mt-3">Year</Col>
                            <Col xs={12} className="text-black mb-3 md-md-0">{year}</Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={10}>
                        <Row className="m-0 h8 public-san">
                            <Col xs={12} className="text-secondary text-start">Description</Col>
                            <Col xs={12} className="text-black text-start">{description}</Col>
                        </Row>
                    </Col>
                </Row>
            </Row>
        )
    }  

    const renderArtist = () => {
        return (
            <div>
                {message ? <div className='flash-messages'>{message}</div> : <div></div>} 
                <div className='background-one'>
                    {profileArt && profileArt.length!==0 ? 
                    <Row className='m-0 bg-black'>
                        <Col className='d-none d-md-block col-md-2 text-light'></Col>
                        <Col className='col-12 col-md-8 text-light p-0'>
                            <Row className='m-0 text-center'>                                                       
                                <Carousel className="hero-images p-5" >                                    
                                    {renderImages()}
                                </Carousel>
                                <Col className="d-none d-md-block col-md-2 p-0"><Button href="#artist" className="text-custom-4 text-four btn border-0 p-0 p-md-1 fw-bold bg-black">The Artist</Button></Col>
                                <Col className="d-none d-md-block col-md-2 p-0"><Button href="#portfolio" className="text-custom-4 text-four btn border-0 p-0 p-md-1 fw-bold bg-black">Portfolio</Button></Col>
                                <Col className="d-none d-md-block col-md-2 p-0"><Button href="#shop" className="text-custom-4 text-four btn border-0 p-0 p-md-1 fw-bold bg-black">Shop</Button></Col>
                                <Col className="d-none d-md-block col-md-2 p-0"><Button href="#about" className="text-custom-4 text-four btn border-0 p-0 p-md-1 fw-bold bg-black">About</Button></Col>
                                <Col className="d-none d-md-block col-md-2 p-0"><Button href="#support" className="text-custom-4 text-four btn border-0 p-0 p-md-1 fw-bold bg-black">Support</Button></Col>
                                <Col className="d-none d-md-block col-md-2 p-0"><Button href="#press" className="text-custom-4 text-four btn border-0 p-0 p-md-1 fw-bold bg-black">Press</Button></Col>                                                             
                            </Row>
                        </Col>
                        <Col className='d-none d-md-block col-md-2 text-light'></Col>
                    </Row>
                    : <div></div>}
                    <Row className='m-0 mb-5 bg-white' id="artist">
                        <Col className='d-none d-md-block col-md-1'></Col>
                        <Col className='col-12 col-md-10'>
                            <Row className='m-0'>
                                <Col className="text-end artist-details" xs={12} sm={4}>
                                    {artist.profile_image ? <img className="shadow p-2 p-sm-3 p-md-0 mb-5 mt-5" src={artist.profile_image} alt='profile'/> :
                                    <img className="shadow p-2 p-sm-3 p-md-0 mb-5 mt-5" src={img_placeholder} alt='profile'/>}
                                </Col>
                                <Col xs={12} sm={8} className="mt-5 mt-md-0">
                                    <Row className='m-0 p-0 p-md-2 p-lg-5'>
                                        <Col className="text-start h5-text text-black ps-0 ps-sm-2 ps-md-2" xs={6}>
                                            {artist.user.username}
                                        </Col>
                                        <Col className="text-end text-black" xs={6}>
                                            {artist.instagram_url ? <a href={artist.instagram_url}><FontAwesomeIcon className='pe-2 ps-2 btn hover' icon={faInstagram} /></a>: ""}
                                            {artist.twitter_url ? <a href={artist.twitter_url}><FontAwesomeIcon className='pe-2 ps-2 btn hover' icon={faTwitterSquare} /></a>: ""}
                                            {artist.facebook_url ? <a href={artist.facebook_url}><FontAwesomeIcon className='pe-2 ps-2 btn hover' icon={faFacebookSquare} /></a>: ""}
                                        </Col>
                                            {artist.website_url ? <Col className="text-start body-text text-secondary mt-1 mt-md-2" xs={12}><a className="text-decoration-none text-secondary hover" href={artist.website_url}>{artist.website_url}</a></Col> : <div></div>}
                                            {artist.social_url ? <Col className="text-start body-text text-secondary mt-1 mt-md-2" xs={12}><a className="text-secondary hover text-decoration-none" href={artist.social_url} target="_blank">@{artist.user.username}</a></Col> : <div></div>}
                                        <Col className="text-start custom-grey mt-3 mt-md-5 tags-list pt-3" xs={12}>
                                            {splitTags(artist.tags)}  
                                        </Col>
                                        <Col className="text-start body-text text-secondary mt-1 mt-md-2" xs={12}>{artist.resume}</Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col className='d-none d-md-block col-md-1'></Col>
                    </Row>
                    {artistPorfolio && artistPorfolio.length!==0 ?
                    <Row className='m-0 mt-5 mb-5 background-one' id="portfolio">
                        <Col className='d-none d-md-block col-md-1'></Col>
                        <Col className='col-12 col-md-10'>
                            <Row className='m-0'>
                                <Col className='h5-text text-start mb-md-5'>Porfolio<span className='text-custom-1'>.</span></Col>
                                <Row className='m-0'>
                                    <Col className='d-none d-md-block col-md-1'></Col>
                                    <Col className='col-12 col-md-10 p-3'>
                                        <Row className='m-0'>
                                            {renderPorfolio()}
                                        </Row>                                        
                                    </Col>
                                    <Col className='d-none d-md-block col-md-1'></Col>
                                </Row>
                            </Row>
                        </Col>
                        <Col className='d-none d-md-block col-md-1'></Col>
                    </Row>
                    : <div></div> }
                    {shop && shop.length!==0 ?
                    <Row className='m-0 background-one mb-5' id="shop">
                        <Col className='d-none d-md-block col-md-1'></Col>
                        <Col className='col-12 col-md-10'>
                            <Row className='m-0'>
                                <Col className='h5-text text-start fw-bold mt-3 mb-md-5'>Shop<span className='text-custom-1'>.</span></Col>
                                <Row className='m-0 artist-shop scrolling-wrapper'>
                                    <Col className="btn my-auto scroll-button p-0" xs={1} onClick={handleImageLeft}><FontAwesomeIcon className='pe-2 ps-2 btn hover text-secondary' icon={faAngleLeft} /></Col>
                                    <Col xs={10} className="artist-shop text-start" id="container">
                                        {renderShop()}
                                    </Col> 
                                    <Col className="btn my-auto scroll-button p-0" xs={1} onClick={handleImageRight}><FontAwesomeIcon className='pe-2 ps-2 btn hover text-secondary' icon={faAngleRight} /></Col>                                  
                                </Row>
                            </Row>
                        </Col>
                        <Col className='d-none d-md-block col-md-1'></Col>
                    </Row>
                    : <div></div>}
                    {about ?
                    <Row className='m-0 mt-5 background-one mb-5' id="about">
                        <Col className='d-none d-md-block col-md-1'></Col>
                        <Col className='col-12 col-md-10'>
                            <Row className='m-0'>
                                <Col className='h5-text text-start fw-bold mt-3 mb-1 mb-md-5'>About<span className='text-custom-1'>.</span></Col>
                                <Row className='m-0 mb-5'>
                                    <Col className='d-none d-md-block col-lg-1'></Col>                                   
                                    <Col className="text-start" xs={12} lg={6}>
                                        <Row className='m-0'>
                                            <Col xs={12} className="h6-text text-black mb-3">Artist statement.</Col>
                                            <Col xs={12} className="body-text text-secondary h6">{about ? about.statement : <div></div>}</Col>
                                            <Col xs={12} className="h6-text text-black mb-3 mt-3">Story.</Col>
                                            <Col xs={12} className="body-text text-secondary h6">{about ? about.story : <div></div>}</Col>
                                        </Row>                                        
                                    </Col>
                                    <Col xs={12} lg={5} className="mt-4 pt-2 ">
                                        <Row className='m-0'>
                                            <Col xs={12} className="h6 text-secondary  mb-3 mt-2 text-start">
                                                <span className='text-custom-1'>Work as</span>: {about ? about.work_as : <div></div>}
                                            </Col>                                            
                                            <Col xs={12} className="h6 text-secondary mb-3 mt-3 text-start">
                                                <span className='text-custom-1'>Makes</span>: {about ? about.makes : <div></div>}
                                            </Col>
                                            <Col xs={12} className="h6 text-secondary  mb-3 mt-3 text-start">
                                                <span className='text-custom-1'>Interests</span>: {about ? about.interests : <div></div>}
                                            </Col>
                                        </Row>  
                                    </Col>                                  
                                </Row>
                            </Row>
                        </Col>
                        <Col className='d-none d-md-block col-md-1'></Col>
                    </Row>
                    : <div></div>}
                    {support.support_one && support.support_two ?
                    <Row className='m-0 mt-5 background-one mb-5' id="support">
                        <Col className='d-none d-md-block col-md-1'>{support.support_one}</Col>
                        <Col className='col-12 col-md-10 p-0'>
                            <Row className='m-0'>
                                <Col className='h5-text text-start fw-bold mt-3 mb-md-5'>Support<span className='text-custom-1'>.</span></Col>
                                <Row className='m-0 artist-shop scrolling-wrapper p-0'>
                                <Col className="col-12 d-md-none h3 text-fout fw-bold support-banner text-center mt-3">The <span className='text-custom-2'>smallest</span> contribution, goes a really <span className='text-custom-1'>long way!</span></Col>
                                    <Col className="btn my-auto scroll-button p-0" xs={2} md={1} onClick={handleImageLeftCard}><FontAwesomeIcon className='pe-2 ps-2 btn hover text-secondary' icon={faAngleLeft} /></Col>
                                    <Col xs={8} md={7} className="artist-shop text-start" id="container_2">
                                        <Row className='m-0'>
                                            {renderCard()}
                                        </Row>                                        
                                    </Col>
                                    <Col className="d-none d-md-block col-md-3 h6-text support-banner text-start mt-3">The <span className='text-custom-2'>smallest</span> contribution, goes a really <span className='text-custom-1'>long way!</span></Col>
                                    <Col className="btn my-auto scroll-button p-0" xs={2} md={1} onClick={handleImageRightCard}><FontAwesomeIcon className='pe-2 ps-2 btn hover text-secondary' icon={faAngleRight} /></Col>                                  
                                </Row>
                            </Row>
                        </Col>
                        <Col className='d-none d-md-block col-md-1'></Col>
                    </Row>
                    : <div></div>}
                    {press && press.length!==0 ?
                    <Row className='m-0 mt-5 pb-5 background-one' id="press">
                        <Col className='d-none d-md-block col-md-1'></Col>
                        <Col className='col-12 col-md-10 p-0'>
                            <Row className='m-0'>
                                <Col className='h5-text text-start fw-bold mt-3 mb-1 mb-md-5'>Press<span className='text-custom-1'>.</span></Col>
                                <Row className='m-0 artist-shop scrolling-wrapper p-0'>
                                    <Col className="btn my-auto scroll-button p-0" xs={1} onClick={handleImageLeftPress}><FontAwesomeIcon className='pe-2 ps-2 btn hover text-secondary' icon={faAngleLeft} /></Col>
                                    <Col xs={10} className="artist-shop text-start ps-4" id="container_3">
                                        {renderPress()}
                                    </Col> 
                                    <Col className="btn my-auto scroll-button p-0" xs={1} onClick={handleImageRightPress}><FontAwesomeIcon className='pe-2 ps-2 btn hover text-secondary' icon={faAngleRight} /></Col>                                  
                                </Row>
                            </Row>
                        </Col>
                        <Col className='d-none d-md-block col-md-1'></Col>
                    </Row>
                    : <div></div>}
                </div>
                <Modal show={show} onHide={handleClose} animation={true}>
                    <Modal.Header closeButton className='border border-0'>
                    </Modal.Header>
                    <Modal.Body>
                        <ShopForm name={artist.user.username} item={item} close={setSelectedModal}/>
                    </Modal.Body>
                </Modal>

                <Modal show={showArt} onHide={handleArtClose} className="art-modal" size="lg">
                    <Modal.Header closeButton className="border border-0 rounded-0"></Modal.Header>
                    <Modal.Body className="p-0 rounded-0">
                        {singleArt()}
                    </Modal.Body>
                </Modal>                
            </div>            
        )
    }
    
    return (
        <div>{!artist ? null : renderArtist()}</div>
    );
}

export default Visual;