import React from 'react'
import CookieConsent from "react-cookie-consent";


function  Cookie() {

    return (
        <div>
            <CookieConsent
                enableDeclineButton 
                flipButtons
                declineButtonText ="Decline"
                declinebuttonStyle={{fontSize: "16px" }}
                location="bottom"
                debug = {process.env.NODE_ENV === 'development' ? true : false}
                buttonText="Agree"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#cdcdcd" }}
                buttonStyle={{ background:"#fff",  color: "#4e503b", fontSize: "16px" }}
                expires={150}
                >
                <span className='text-black'>This website uses cookies to enhance the user experience.{" "}
                Read more </span><a className="text-deocation-none text-black hover" href="/terms">here</a>
            </CookieConsent>
        </div>
  );
}

export default Cookie;