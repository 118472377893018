import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import contact from '../media/contact.png'

function  ContactForm(props) {

    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [messageForm, setMessageForm] = useState(null)

    //url
    const [url, setUrl] = useState(process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000' : 'https://cifeapi.theflyu2.com');

    const handleName = (event) =>{
        setName(event.target.value)
    }

    const handleEmail = (event) =>{
        setEmail(event.target.value)
    }

    const handleMessageForm = (event) =>{
        setMessageForm(event.target.value)
    }

    const handleEnquiry = (e) => {
        e.preventDefault()
        let data;
        data = { 
            'name': name,
            'email': email,
            'message': messageForm,
        }
    
        fetch(`${url}/myadmin/contact_mail`, {method: 'POST', headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}, body: JSON.stringify(data)}).then(async response => {
            const data = await response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                // handleMessage('Error, Try Again later')
                
            }else if(response.ok){
                props.message("Enquiry has been sent")
                setName("")
                setEmail("")
                setMessageForm("")
            }       
        })
        .catch(error => {
            console.error('There was an error!', error);
        });
    }

    return (
        <Form className='main-contact container shadow border p-sm-3 p-md-3 rounded-2' onSubmit={handleEnquiry}>
            <div className="text-center h3 fw-bold mb-3 hero-text text-dark mt-3"><span className='text-custom-1'>Contact</span> Us</div>
            <div className="p-0 image-two col-8 offset-2 text-center"><img src={contact} alt="heroimage" /></div>  
            <div className='h6 body-text text-secondary p-0 justify-text p-2'>We like to hear from you, any questions and suggestions, feedback, criticism (as long as if it's
                constructive). Feel free to hit us up and we will get back to you as soon as possible.</div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control type="text" placeholder="Name" onChange={handleName} value={name || ""} required/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                <Form.Control type="email" placeholder="name@example.com" onChange={handleEmail} value={email || ""} required/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea3">
                <Form.Control as="textarea" rows={3} placeholder="Message" onChange={handleMessageForm} value={messageForm || ""} required/>
            </Form.Group>
            <Button variant="dark mb-2" type="submit" size="md">
                    Submit
            </Button>
        </Form>
    );
}

export default ContactForm;