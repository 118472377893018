import React from 'react'
import './Footer.css'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import curve from '../media/curve.png'
import logo from '../media/logofooter.png'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons"
import SignUp from '../SignUp/SignUp';
import { useState } from "react"
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form'


function Footer() {

    // SignUp modal
    const [showSignup, setShowSignup] = useState(false);
    const handleSignUpShow = () => setShowSignup(true);

    // contact form
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [messageForm, setMessageForm] = useState(null)

    //url
    const [url, setUrl] = useState(process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000' : 'https://cifeapi.theflyu2.com');

    const handleName = (event) => {
        setName(event.target.value)
    }

    const handleEmail = (event) => {
        setEmail(event.target.value)
    }

    const handleMessageForm = (event) => {
        setMessageForm(event.target.value)
    }

    const handleContact = (e) => {
        e.preventDefault()
        let data;
        data = {
            'name': name,
            'email': email,
            'message': messageForm,
        }

        fetch(`${url}/myadmin/contact_mail`, { method: 'POST', headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }, body: JSON.stringify(data) }).then(async response => {
            const data = await response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                setShow(false)
                handleMessage('Error, Try Again later')

            } else if (response.ok) {
                setShow(false)
                handleMessage('Your enquiry has been sent')
            }
        })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    //flash messages
    const [message, setMessage] = useState(null)

    // handle page layout
    const handleMessage = (message) => {
        setMessage(message)
        setTimeout(() => {
            setMessage(null)
        }, 3000);
    }


    return (
        <div>
            {message ? <div className='flash-messages'>{message}</div> : <div></div>}
            {/* <div className='background-three m-0' xs={12}><img src={curve} alt="heroimage" /></div> */}
            <Row className='m-0 bg-black pt-5'>
                <Col className="text-danger" xs={12} sm={2}><img src={logo} alt="heroimage" /></Col>
                <Col xs={12} md={2}>
                    <Row>
                        <Col xs={12} className="text-danger fw-bold">Artists</Col>
                        <Col xs={12} className="mt-sm-1 mt-md-3"><Button className="text-decoration-none text-secondary hover" variant="link" href="/artist">Find Artists</Button></Col>
                        <Col xs={12} className="mt-sm-1 mt-md-3"><Button className="text-decoration-none text-secondary hover" variant="link" onClick={handleSignUpShow}>Sign Up</Button></Col>
                    </Row>
                </Col>
                <Col xs={12} md={2}>
                    <Row>
                        <Col xs={12} className="text-danger fw-bold">Organizations</Col>
                        <Col xs={12} className="mt-sm-1 mt-md-3"><Button className="text-decoration-none text-secondary hover" variant="link" href="/about">Partners</Button></Col>
                    </Row>
                </Col>
                <Col xs={12} md={2}>
                    <Row>
                        <Col xs={12} className="text-danger fw-bold">Cife</Col>
                        <Col xs={12} className="mt-sm-1 mt-md-3"><Button className="text-decoration-none text-secondary hover" variant="link" href="/about">About</Button></Col>
                    </Row>
                </Col>
                <Col xs={12} md={2}>
                    <Row>
                        <Col xs={12} className="text-danger fw-bold">Help</Col>
                        <Col xs={12} className="mt-sm-1 mt-md-3"><Button className="text-decoration-none text-secondary hover" variant="link">FAQs</Button></Col>
                        <Col xs={12} className="mt-sm-1 mt-md-3"><Button className="text-decoration-none text-secondary hover" variant="link" onClick={handleShow} >Contact Us</Button></Col>
                        <Col xs={12} className="mt-sm-1 mt-md-3"><Button className="text-decoration-none text-secondary hover" variant="link" href="/terms">Community Guidelines</Button></Col>
                    </Row>
                </Col>
            </Row>
            <Row className='bg-black m-0 footnotes p-2'>
                <Col xs={12} sm={6}><Button className='pe-2 ps-2 text-decoration-none footnotes hover' href="/terms" variant="link">Terms & Conditions</Button>  |  <Button className='pe-2 ps-2 text-decoration-none footnotes hover' variant="link" href="/terms">Privacy & Conditions</Button></Col>
                <Col className='center' xs={12} sm={6}><a href="/" className='pe-2 ps-2 btn footnotes hover'>@Cife</a> 
                    <a href="https://www.instagram.com/cife.space/" target="_blank"><FontAwesomeIcon className='pe-2 ps-2 btn footnotes hover' icon={faFacebook} /></a> 
                    <a href="https://www.instagram.com/cife.space/" target="_blank"><FontAwesomeIcon className='pe-2 ps-2 btn footnotes hover' icon={faTwitter} /></a>
                    <a href="https://www.instagram.com/cife.space/" target="_blank"><FontAwesomeIcon className='pe-2 ps-2 btn footnotes hover' icon={faInstagram} /></a>
                </Col>
            </Row>
            <SignUp show={showSignup} close={() => setShowSignup(false)} message={handleMessage} />
            <Modal className="contact-form" show={show} onHide={handleClose}>
                <Modal.Header className="border border-0" closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Form className='p-sm-3 p-md-3 rounded-2 mx-auto pt-0' onSubmit={handleContact}>
                        <div className="text-start h3 fw-bold mb-3 hero-text text-dark"><span className='text-custom-1'>Contact</span> Us</div>
                        <div className='h6 text-secondary p-0 justify-text'>We like to hear from you, any questions and suggestions, feedback, criticism (as long as if it's
                            constructive). Feel free to hit us up and we will get back to you as soon as possible.</div>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control type="text" placeholder="Name" onChange={handleName} required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            <Form.Control type="email" placeholder="name@example.com" onChange={handleEmail} required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea3">
                            <Form.Control as="textarea" rows={3} placeholder="Message" onChange={handleMessageForm} required />
                        </Form.Group>
                        <Button variant="dark mb-2" type="submit" size="md">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Footer;