import React from 'react'
import './App.css';
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import HomePage from '../HomePage/HomePage';
import About from '../AboutPage/AboutPage';
import Artist from '../Artists/Artists';
import Visual from '../Profile/Visual';
import Music from '../Profile/Music';
import Dance from '../Profile/Dance';
import Portfolio from '../Portfolio/Portfolio';
import Terms from '../Terms/Terms';
import Cookie from '../Cookie/Cookie';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

function App() {

    return (   
      <div className="App">
        <BrowserRouter>
          <Routes className="App">
            <Route path="/" element={<div><Header page={'home'}/><HomePage /><Footer /><Cookie/></div>} />
            <Route path="/about" element={<div><Header page={'about'}/><About /><Footer /></div>} />
            <Route path="/artist" element={<div><Header page={'artist'}/><Artist /><Footer /></div>} />
            <Route path="/terms" element={<div><Header page={'artist'}/><Terms /><Footer /></div>} />
            <Route path="/profile/visual/:id" element={<div><Header page={'profile'}/><Visual/><Footer /></div>} />
            <Route path="/profile/music/:id" element={<div><Header page={'profile'}/><Music /><Footer /></div>} />
            <Route path="/profile/dance/:id" element={<div><Header page={'profile'}/><Dance /><Footer /></div>} />
            <Route path="/visual/portfolio/:id" element={<div><Header page={'profile'}/><Portfolio /><Footer /></div>} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;