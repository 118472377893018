import React from 'react'
import './Profile.css'
import { useParams } from 'react-router-dom';
import { useState, useEffect } from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagram, faTwitterSquare} from "@fortawesome/free-brands-svg-icons"
import img_placeholder from '../media/avatar.png' 


function  Music() {
    const id  = useParams();
    const [artist, setArtists] = useState(null);

    //url
    const [url, setUrl] = useState(process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000' : 'https://cifeapi.theflyu2.com');

    useEffect(() => { 
        async function fetchArtist() {
            const response = await fetch(`${url}/profiles/${id.id}`);
            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            const artist = await response.json();
            setArtists(artist); 
            return artist;
            }
            fetchArtist().catch(error => {
            console.log(error.message); // 'An error has occurred: 404'
        }); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const splitTags = (tags) => {
        const tagList = tags.split(",")
        if(tagList[0]=='{}'){
            tagList[0]='artist'
        }
        return (
            (tagList|| []).map((element, index)=>                       
            <div className='text-start tags me-1 ps-2 pe-2 rounded-4 p-1 d-inline font-one' key={index}>{element}</div>
        )
        )
    }

    const renderArtist = () => {
        return (
            <div class='artist-panel'>
                <div>
                    <Row className='m-0 bg-black'>
                        <Col className='d-none d-md-block col-md-2 text-light'></Col>
                        <Col className='col-12 col-md-8'></Col>
                        <Col className='d-none d-md-block col-md-2 text-light'></Col>
                    </Row>
                    <Row className='m-0 mt-5 mb-5' id="artist">
                        <Col className='d-none d-md-block col-md-2'></Col>
                        <Col className='col-12 col-md-8'>
                            <Row className='m-0'>
                                <Col className="text-end artist-details" xs={12} sm={4}>
                                    {artist.profile_image ? <img className="shadow p-2 p-sm-3 p-md-0 mb-5 mt-5" src={artist.profile_image} alt='profile'/> :
                                    <img className="shadow p-2 p-sm-3 p-md-0 mb-5 mt-5" src={img_placeholder} alt='profile'/>}
                                </Col>
                                <Col xs={12} sm={8} className="mt-5 mt-md-0">
                                    <Row className='m-0 p-0 p-md-2 p-lg-5'>
                                        <Col className="text-start h3 text-black ps-0 ps-sm-2 ps-md-2" xs={6}>
                                            {artist.user.username}
                                        </Col>
                                        <Col className="text-end text-black" xs={6}>
                                            {artist.instagram_url ? <a href={artist.instagram_url}><FontAwesomeIcon className='pe-2 ps-2 btn hover' icon={faInstagram} /></a>: <div></div>}
                                            {artist.twitter_url ? <a href={artist.twitter_url}><FontAwesomeIcon className='pe-2 ps-2 btn hover' icon={faTwitterSquare} /></a>: <div></div>}
                                            {artist.facebook_url ? <a href={artist.facebook_url}><FontAwesomeIcon className='pe-2 ps-2 btn hover' icon={faFacebookSquare} /></a>: <div></div>}
                                        </Col>
                                            {artist.website_url ? <Col className="text-start custom-grey mt-1 mt-md-2" xs={12}><a className="text-decoration-none text-secondary hover" href={artist.website_url}>{artist.website_url}</a></Col> : <div></div>}
                                            {artist.social_url ? <Col className="text-start custom-grey mt-1 mt-md-2" xs={12}>{artist.social_url}</Col> : <div></div>}
                                        <Col className="text-start custom-grey mt-3 mt-md-5" xs={12}>
                                            {splitTags(artist.tags)}  
                                        </Col>
                                        <Col className="text-start custom-grey mt-1 mt-md-2" xs={12}>{artist.resume}</Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col className='d-none d-md-block col-md-2'></Col>
                    </Row>
                    <div className='h1 text-center fw-bold mt-5 mb-5'>Under <span className='text-custom-1'>Construction</span>, Come Back <span className='text-custom-2'>Soon</span>.</div>
                </div>
            </div>            
        )
    }
    
    return (
        <div>{!artist ? null : renderArtist()}</div>
    );
}

export default Music;