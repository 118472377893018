import React from 'react'
import './Artist.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import img_placeholder from '../media/avatar.png' 
import { Button } from 'react-bootstrap';
import {useState, useEffect } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faAngleDoubleLeft, faLocationDot, faPalette, faMusic, faTheaterMasks  } from '@fortawesome/free-solid-svg-icons'
import Form from 'react-bootstrap/Form';


function  ArtistLarge(props) {

    // console.log(props.handleTag)
    const [artists, setArtists] = useState(null);
    const [masterArtist, setMasterArtists] = useState(null);

    // Collapsable Menu3
    const [side, setSide] = useState(2);
    const [show, setShow] = useState(true);
    const [filterWord, setfilterWord] = useState('Filter');
    const [main, setMain] = useState(10);

    const [filter, setFilter] = useState(<div className='double-arrow'><FontAwesomeIcon icon={faAngleDoubleLeft} /></div>);

    // radio selection
    const [selected, setSelected] = useState('all')

    //url
    const [url, setUrl] = useState(process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000' : 'https://cifeapi.theflyu2.com');


    const handleSideBar = () => {
        console.log(side)
        if(side == '2'){
            setSide(1)
            setMain(11)
            setFilter(<div ><FontAwesomeIcon icon={faAngleDoubleRight} /></div>)
            setfilterWord(null)
            setShow(false)
            props.handleClick(2)
        }else{
            setSide(2)
            setMain(10)
            setFilter(<div className='double-arrow'><FontAwesomeIcon icon={faAngleDoubleLeft} /></div>)
            setfilterWord('Filter')
            setShow(true)
            props.handleClick(1)
        }
    }

    const handleRadio = (event) => {
        setSelected(event.target.value)
        props.tags(event.target.value)
        let tempoArtists = []
        if(event.target.value==='all'){
            setArtists(masterArtist)
        }else {
            (masterArtist || []).map((element)=> {
                if(element.discipline===event.target.value){
                    tempoArtists.push(element)
                }
            })
            setArtists(tempoArtists)
        }
    }

    const splitTags = (tags) => {
        const tagList = tags.split(",")
        if(tagList[0]=='{}'){
            tagList[0]='artist'
        }
        return (
            (tagList|| []).map((element, index)=>                       
            <div className='text-start tags me-1 ps-2 pe-2 rounded-4 p-1 d-inline font-one' key={index}>{element}</div>
        )
        )
    }

    // render all artists and render first 3
    useEffect(() => {        
        async function fetchCharacters() {
            const response = await fetch(`${url}/profiles/all_profiles`);
            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            const artists = await response.json();
            setArtists(artists);
            setMasterArtists(artists)
            return artists;
            }
            fetchCharacters().catch(error => {
            console.log(error.message); // 'An error has occurred: 404'
        });        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setSelected(props.handleTag)
        setArtists(null)
        setTimeout(() => {
            let tempoArtists = [];
            if(props.handleTag==='all'){
                if(props.handleTerm){
                    (masterArtist || []).map((element)=> {
                        if((element.user.username.toLowerCase()).includes((props.handleTerm || '').toLowerCase())){
                            tempoArtists.push(element)
                        }
                    })
                    setArtists(tempoArtists)
                }else{
                    setArtists(masterArtist)
                }            
            }else {
                (masterArtist || []).map((element)=> {
                    if(element.discipline===props.handleTag && (element.user.username.toLowerCase()).includes((props.handleTerm || '').toLowerCase())){
                        tempoArtists.push(element)
                    }
                })
                setArtists(tempoArtists)
            }
        }, 1);   
       
    }, [props.handleTag])


    useEffect(() => { 
        let tempoArtists = [];
        if(props.handleTag==='all'){
            (masterArtist || []).map((element)=> {
                if((element.user.username.toLowerCase()).includes(props.handleTerm.toLowerCase())){
                    tempoArtists.push(element)
                }
            })
            setArtists(tempoArtists)
        }else {
            (masterArtist || []).map((element)=> {
                if(element.discipline===props.handleTag && (element.user.username.toLowerCase()).includes(props.handleTerm.toLowerCase())){
                    
                    tempoArtists.push(element)
                    
                }
                setArtists(tempoArtists)
            })
        }
        
    }, [props.handleTerm])

    // get artist icons
    const getIcon = (discipline) => {
        switch(discipline) {
            case 'Visual Arts':
                return <FontAwesomeIcon className="text-light icon" icon={faPalette}/>;
            case 'Music':
                return <FontAwesomeIcon className="text-light icon" icon={faMusic} />;
            case 'Dance':
                return <FontAwesomeIcon className="text-light icon" icon={faTheaterMasks} />;
            default:
                return <FontAwesomeIcon className="text-light icon" icon={faTheaterMasks} />;
          }
    }

    const redirectArtist = (artist) => {
        switch(artist) {
            case 'Visual Arts':
              return 'visual';
            case 'Music':
                return 'music';
            case 'Dance':
              return 'dance';
            default:
              return 'Music';
        }
    }
    
    const renderArtists = (artists|| []).map((element, index)=>                       
        <Col className="text-light mb-2 text-dark text-one p-0 fade-in-image" id={element.id} xs={12} sm={6} lg={4} xl={4} xxl={3} key={index}>
            <Row className='m-0'>
                <Col xs={12}></Col>
                <Col xs={12} className='artist-large-image text-start p-sm-1 pt-0 pb-0 p-md-2'>
                    <div className='image-tag d-inline rounded-4 pt-1 pb-1 p-1 p-md-1'>
                        {getIcon(element.discipline)} {element.discipline}
                    </div>
                    <div className='image-box rounded-2'>
                        {element.profile_image ?
                        <a href={`/profile/${redirectArtist(element.discipline)}/${element.id}`}><img className="rounded-2" src={element.profile_image} alt="profile"/></a>
                        :  <a href={`/profile/${redirectArtist(element.discipline)}/${element.id}`}><img className="rounded-2" src={img_placeholder} alt="profile"/></a>
                        }                       
                    </div>                    
                </Col>
                <div className='mt-2 ps-1 ps-md-4'>
                    <Col className="text-start h7 text-dark p-1 m-0 text-one public-san" xs={12}>{element.user.username}</Col>
                    <Col className="text-start h16 small-text text-secondary p-1 m-0 public-san" xs={12}>{element.discipline}</Col>                    
                    <Col className="text-start p-0 m-0 tags-list" xs={12}>
                    <Row className="m-0 d-inline ">
                        {splitTags(element.tags)}   
                    </Row>                        
                    </Col>
                    <Col className="text-start h8 text-secondary p-1 pt-0 mt-0 mt-md-1 mb-3 public-san" xs={12}><FontAwesomeIcon className="text-custom-1 me-2" icon={faLocationDot} /> {element.location}</Col>
                </div>   
            </Row>                                                            
        </Col>
    )

    return (
        <div className='m-3'>        
            <Row className="m-0 artist-panel">
                <Col xs={side} className="side-filter p-0 d-none d-md-block bg-transparent">
                    <Row className={show ? 'm-0' : 'm-0 w-75'}>
                        <Col xs={12} className="p-2 rounded-top m-0 mt-2 filter-header text-custom-3 text-start">
                            <span className={show ? 'text-start h6 ps-2 pe-2' : 'text-start h6 ps-2'}>{filterWord}</span><Button variant="text-decoration-none hover text-custom-3 p-0" onClick={handleSideBar}>  {filter}</Button>
                        </Col>
                        {show ? 
                            <Col className="filter-body text-secondary text-start ps-2" xs={12}>
                               <Row className='m-0'>
                                   <Col xs={12} className="mt-2">Displine -</Col>
                                   <Col xs={12} className="ms-3">
                                    <Form>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`} className="mb-3">
                                                <Form.Check
                                                    checked={selected === 'all'}      
                                                    label="All"
                                                    name="group1"
                                                    type={type}
                                                    id={`inline-${type}-1`}
                                                    onChange={handleRadio}
                                                    value='all'
                                                />       
                                                <Form.Check
                                                    checked={selected === 'Visual Arts'}          
                                                    label="Visual"
                                                    name="group1"
                                                    type={type}
                                                    id={`inline-${type}-2`}
                                                    onChange={handleRadio}
                                                    value='Visual Arts'
                                                />        
                                                <Form.Check
                                                    checked={selected === 'Dance'}          
                                                    label="Dance"
                                                    name="group1"
                                                    type={type}
                                                    id={`inline-${type}-2`}
                                                    onChange={handleRadio}
                                                    value='Dance'
                                                />        
                                                <Form.Check
                                                    checked={selected === 'Music'}           
                                                    label="Music"
                                                    name="group1"
                                                    type={type}
                                                    id={`inline-${type}-2`}
                                                    onChange={handleRadio}
                                                    value="Music"
                                                />
                                                </div>
                                            ))}
                                        </Form>
                                   </Col> 
                                </Row>
                            </Col>
                        : <div></div>}                    
                    </Row>
                </Col>
                <Col xs={main} className="ps-0 pe-0 mt-2">
                    <Row className="m-0 mb-5">               
                        <Col xs={12} className='h1 p-0'>
                            <Row className='m-0 render-artist'>
                                {renderArtists}
                            </Row>
                        </Col>    
                    </Row>
                </Col>                
            </Row>
        </div>
    );
}
export default ArtistLarge;