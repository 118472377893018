import React from 'react'
import './Header.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import logo from '../media/logo.png'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Button } from 'react-bootstrap';
import {useState } from "react"
import SignUp from '../SignUp/SignUp';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons'


function Header(props) {

    // canvas
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const page = props.page;

    
    // SignUp modal
    const [showSignup, setShowSignup] = useState(false);
    const handleSignUpShow = () => [setShowSignup(true), setShow(false)];

    //flash messages
    const [message, setMessage] = useState(null)

    // handle page layout
    const handleMessage = (message) => {
        setMessage(message)
        setTimeout(() => {
            setMessage(null)
        }, 3000);
    }
                  
    return (        
        <div>
            {message ? <div className='flash-messages'>{message}</div> : <div></div>}            
            <Row className='header m-0 my-auto'>
                <Col className='h3 text-start my-auto' xs={3}><a className='text-decoration-none' href="/"><img src={logo} alt="logo" /></a></Col>
                <Col className='h3 text-end col-9 d-sm-none my-auto'><Button className='text-decoration-none text-dark hover' variant="link" onClick={handleShow}> <FontAwesomeIcon icon={show ? faClose : faBars } /></Button></Col>
                <Col className='body-text text-end my-auto text-dark d-none d-sm-block col-sm-9'>
                    <Button href="/" className={page==='home' ? 'text-decoration-none text-dark hover body-text fw-bold' : 'text-decoration-none text-dark hover body-text'} variant="link" id="home">Home</Button>
                    <Button href="/artist" className={page==='artist' ? 'text-decoration-none text-dark hover body-text fw-bold' : 'text-decoration-none text-dark hover body-text'} variant="link" id="home">Artists</Button>
                    <Button href="/about" className={page==='about' ? 'text-decoration-none text-dark hover body-text fw-bold' : 'text-decoration-none text-dark hover body-text'} variant="link" id="home">About</Button>
                    <Button className='text-decoration-none text-dark hover body-text' variant="link" onClick={handleSignUpShow}>Sign-up</Button>
                </Col>             
            </Row>            
            <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
            <Offcanvas.Title><a className='text-decoration-none' href="/"><img src={logo} alt="logo" /></a></Offcanvas.Title>
            </Offcanvas.Header>
                <Offcanvas.Body>
                    <Button href="/" className={page==='home' ? 'text-decoration-none text-dark hover text-one d-block text-start fw-bold' : 'text-decoration-none text-dark hover text-one d-block text-start'} variant="link" id="home">Home</Button>
                    <Button href="/artist" className={page==='artist' ? 'text-decoration-none text-dark hover text-one d-block text-start fw-bold' : 'text-decoration-none text-dark hover text-one d-block text-start'} variant="link" id="home">Artists</Button>
                    <Button href="/about" className={page==='about' ? 'text-decoration-none text-dark hover text-one d-block text-start fw-bold' : 'text-decoration-none text-dark hover text-one d-block text-start'} variant="link" id="home">About</Button>
                    <Button className='text-decoration-none text-dark hover text-one d-block text-start' variant="link" onClick={handleSignUpShow}>Sign-up</Button>
                </Offcanvas.Body>
            </Offcanvas>
            <SignUp show={showSignup}  close={() => setShowSignup(false)} message={handleMessage}/>
        </div>        
    );
}

export default Header;