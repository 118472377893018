import React from 'react'
import'./SignUp.css'
import { Form, Modal  } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { useState } from "react"



function  SignUp(props) {

    // handle early signup
    const [PreRegName, setPreRegName] = useState(null);
    const [PreRegEmail, setPreRegEmail] = useState(null);
    const [selected, setSelected] = useState(null)
    const [is_artist, setIsArtists] = useState(false)
    const [other, setOther] = useState(null)

    //url
    const [url, setUrl] = useState(process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000' : 'https://cifeapi.theflyu2.com');


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(null);
    // const [message, setMessage] = useState(null)

    const handleRegistrationName  = (event) => {
        setPreRegName(event.target.value)
    }

    const handleRegistrationEmail = (event) => {
        setPreRegEmail(event.target.value)
    }

    const handleRadio = (event) => {
        setSelected(event.target.value)
    }

    const handleArtist = () => {
        setSelected('Visual Arts')
        if(is_artist){
            setIsArtists(false)
        }else(
            setIsArtists(true)
        )        
    }

    const handleOther = (event) => {
        setOther(event.target.value)
    }

    const handlePreSignUpSubmit = (e) => {
        e.preventDefault()
        let data;
        if(other){
            data = { 
                'name': PreRegName,
                'email': PreRegEmail,
                'discipline': other
            }
        }else{
            data = { 
                'name': PreRegName,
                'email': PreRegEmail,
                'discipline': selected
            }
        }
        
        fetch(`${url}/profiles/presign_up`, {method: 'POST', headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}, body: JSON.stringify(data)}).then(async response => {
            const data = await response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                setShow(props.close)
                
            }else if(response.ok){
                setShow(props.close)
                props.message('Thanks for Signing Up, We have sent you a Mail')
            }       
        })
        .catch(error => {
            console.error('There was an error!', error);
        });
    }
   
    return (
        <div>
        <Modal className="signup-form" show={props.show} onHide={props.close} onClick={handleClose}>
            <Modal.Header closeButton className='border-0 text-center'></Modal.Header>
            <Modal.Title><div xs={12} className="text-center h1 fw-bold mb-3 mt-3">Sign Up for Early Access</div></Modal.Title>     
            <Modal.Body>
            <p className='text-center text-dark body-text'>Sign Up for Early Access Be the first to know when we launch Cife to the public. As Cife’s first users, you are entitled to exclusive offers!</p>
                <Form className="p-0 p-md-2" onSubmit={handlePreSignUpSubmit}>
                    <Form.Label className="text-secondary body-text">Email</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Control type="email" placeholder="name@example.com" onChange={handleRegistrationEmail} value={props.email} required/>
                    </Form.Group>
                    <Form.Label className="text-secondary body-text">Name</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Control type="text" placeholder="artist pseudonym or name" onChange={handleRegistrationName} required/>
                    </Form.Group>
                    <Form.Check
                        checked={is_artist === true}
                        onClick={handleArtist}
                        onChange={e => {}} 
                        inline
                        label="I am an artist"
                        value="Visual Arts"
                        />
                    {is_artist ? <div className='p-2 text-secondary'>My <span className='text-dark'>primary</span> art practice is:</div> : <div></div>}
                    {is_artist ?
                    <Form.Group>
                        {['radio'].map((type) => (
                            <div key={`inline-${type}`} className="mb-3">
                            <Form.Check
                                checked={selected === 'Visual Arts'}
                                onClick={handleRadio}
                                onChange={e => {}} 
                                label="Visual Arts"
                                name="group1"
                                value="Visual Arts"
                                type={type}
                                required
                                id={`inline-${type}-1`}
                            />
                            <Form.Check
                                checked={selected === 'Dance'}
                                onClick={handleRadio}
                                onChange={e => {}} 
                                label="Dance"
                                name="group1"
                                value="Dance"
                                type={type}
                                required
                                id={`inline-${type}-2`}
                            />
                            <Form.Check
                                checked={selected === 'Music'}
                                onClick={handleRadio}
                                onChange={e => {}}                                 
                                label="Music"
                                name="group1"
                                value="Music"
                                type={type}
                                required
                                id={`inline-${type}-2`}
                            />
                            <Form.Check
                                checked={selected === 'Other'}
                                onClick={handleRadio}
                                onChange={e => {}}                               
                                label="Other"
                                name="group1"
                                inline
                                value='Other'
                                type={type}
                                required
                                id={`inline-${type}-2`}
                            />
                            {selected==='Other' ? <input className='border border-seconary rounded-1 border-2 ps-2' name="group1" onChange={handleOther} required></input>:
                                <input className='border border-seconary rounded-1 border-2 ps-2' name="group1"></input>}
                                   
                            </div>
                        ))}
                    </Form.Group>
                    : <div></div> }
                    <Button className="mx-auto w-100 mt-3 mb-5" variant="dark" size="md" type="submit">Sign Up</Button> 
                </Form>
            </Modal.Body>
        </Modal>
        </div>
  );
}

export default SignUp;