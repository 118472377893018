import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


function  ShopForm(props) {

    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [message, setMessage] = useState(null)

    const handleName = (event) =>{
        setName(event.target.value)
    }

    const handleEmail = (event) =>{
        setEmail(event.target.value)
    }

    const handleMessage = (event) =>{
        setMessage(event.target.value)
    }

    //url
    const [url, setUrl] = useState(process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000' : 'https://cifeapi.theflyu2.com');

    const handleBuyItem = (e) => {
        e.preventDefault()
        let data;
        data = { 
            'name': name,
            'email': email,
            'message': message,
            'item_id': props.item,
        }
    
        fetch(`${url}/profiles/buy_art`, {method: 'POST', headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}, body: JSON.stringify(data)}).then(async response => {
            const data = await response.json();
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response statusText
                // props.closeZa(true)
                
            }else if(response.ok){
                props.close(false)
                // props.message('Thanks for Signing Up, We have sent you a Mail')
            }       
        })
        .catch(error => {
            console.error('There was an error!', error);
        });
    }
    

    return (
        <Form onSubmit={handleBuyItem}>
            <div className="text-start h3 fw-bold mb-3 hero-text text-dark"><span className='text-custom-1'>Contact</span> {props.name}</div>
            <div className='h6 text-secondary p-0 justify-text'>Please Provide yout detail and {props.name} will get in touch</div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control type="text" placeholder="Full Name" onChange={handleName} required/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                <Form.Control type="email" placeholder="name@example.com" onChange={handleEmail} required/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea3">
                <Form.Control as="textarea" rows={3} placeholder="Message" onChange={handleMessage} required/>
            </Form.Group>
            <Button variant="dark mb-2" type="submit" size="md">
                    Submit
            </Button>
        </Form>
    );  
}

export default ShopForm;