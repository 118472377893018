import React from 'react'
import './HomePage.css'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form';
import image from '../media/hero-image.png'
import imagetwo from '../media/imagetwo.png'
import imagethree from '../media/imagethree.png'
import imagefour from '../media/imagefour.png'
import curve from '../media/curve.png'
import { Button } from 'react-bootstrap'
import ContactForm from '../Contact/Contact';
import { useState, useEffect } from "react"
import SignUp from '../SignUp/SignUp';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faPalette, faMusic, faTheaterMasks, faArrowRight } from '@fortawesome/free-solid-svg-icons'


function HomePage(props) {   

    // SignUp modal
    const [showSignup, setShowSignup] = useState(false);
    const handleSignUpShow = () => setShowSignup(true);
    const [email, setEmail] = useState('')

    const [artists, setArtists] = useState(null);
    

    //url
    const [url, setUrl] = useState(process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000' : 'https://cifeapi.theflyu2.com');

    //flash messages
    const [message, setMessage] = useState(null)

    // handle page layout
    const handleMessage = (message) => {
        setMessage(message)
        setTimeout(() => {
            setMessage(null)
        }, 3000);

    }

    // render all artists and render first 3
    useEffect(() => {        
        async function fetchCharacters() {
            const response = await fetch(`${url}/profiles/all_profiles`);
            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            const artists = await response.json();
            let tempo_artist = [];
            
            artists.forEach(element => {
                if(element.discipline=='Visual Arts'){
                    tempo_artist.push(element)
                }
            });            
            setArtists(tempo_artist.splice(0,3));
            return artists;
            }
            fetchCharacters().catch(error => {
            console.log(error.message); // 'An error has occurred: 404'
        });        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const splitTags = (tags) => {
        const tagList = tags.split(",")
        return (
            (tagList|| []).map((element, index)=>                       
           <div className='text-start tags me-1 rounded-4 ps-2 pe-2 p-1 d-inline text-one' key={index}>{element}</div>
        )
        )
    }

    // get artist icons
    const getIcon = (discipline) => {
        switch(discipline) {
            case 'Visual Arts':
                return <FontAwesomeIcon className="text-light" icon={faPalette}/>;
            case 'Music':
                return <FontAwesomeIcon className="text-light" icon={faMusic} />;
            case 'Dance':
                return <FontAwesomeIcon className="text-light" icon={faTheaterMasks} />;
            default:
                return <FontAwesomeIcon className="text-light" icon={faTheaterMasks} />;
          }
    }

    const handleCarouselImages = (id) => {     
            async function fetchImages() {
                const response = await fetch(`http://127.0.0.1:8000/visual_arts/get_profileart/${id}`);
                if (!response.ok) {
                    const message = `An error has occured: ${response.status}`;
                    throw new Error(message);
                }
                const images = await response.json();
                console.log(images)         
                }
                fetchImages().catch(error => {
                console.log(error.message); // 'An error has occurred: 404'
            });
            return (
                <div className='h1'></div>
                )      
    }

    const redirectArtist = (artist) => {
        switch(artist) {
            case 'Visual Arts':
              return 'visual';
            case 'Music':
                return 'music';
            case 'Dance':
              return 'dance';
            default:
              return 'Music';
        }
    }

    const renderArtists = (artists|| []).map((element, index)=>                       
            <Col className="text-light mb-2 text-dark text-one p-0 p-md-1" id={element.id} xs={6} sm={6} md={6} lg={4} key={index}>
                <Row className='m-0'>
                    <Col xs={12}></Col>
                    <Col xs={12} className='artist-image p-1 text-start'>
                        <div className='image-tag d-inline rounded-4 p-1'>
                            {getIcon(element.discipline)} {element.discipline}
                        </div>
                        <div className='image-box rounded-2 home-artist'>
                            <a href={`/profile/${redirectArtist(element.discipline)}/${element.id}`}><img className="rounded-2" src={element.profile_image} alt="profile"/></a>
                        </div>
                    </Col>
                    <div className='mt-2 ps-1 ps-md-4'>
                        <Col className="text-start h7 text-dark p-1 m-0 text-one public-san" xs={12}>{element.user.username}</Col>
                        <Col className="text-start h16 small-text text-secondary p-1 m-0 public-san" xs={12}>{element.discipline}</Col>                    
                        <Col className="text-start p-0 m-0 tags-list" xs={12}>
                        <Row className="m-0 d-inline ">
                            {splitTags(element.tags)}   
                        </Row>                        
                        </Col>
                        <Col className="text-start h8 text-secondary p-1 pt-0 mt-0 mt-md-1 mb-3 public-san" xs={12}><FontAwesomeIcon className="text-custom-1 me-2" icon={faLocationDot} /> {element.location}</Col>
                    </div>                     
                </Row>                                                            
            </Col>
    )

    const handleSignUpEmail = (event) => {
        setEmail(event.target.value)
    }

    return (        
        <div className='p-0'>
            {message ? <div className='flash-messages'>{message}</div> : <div></div>} 
            <Row className='mt-2 mb-5 ms-0 me-0'>
                <Col className="hero-image pe-lg-2 pe-xxl-5" xs={12} md={6}>
                    <img src={image} alt="heroimage" />
                </Col>
                <Col xs={12} md={6} lg={6}>
                    <Row className='mt-5 text-center'>
                        <Col className="d-none d-md-block col-12 text-start h2-text">Find local <span className='text-custom-1'>creatives and</span></Col>
                        <Col className="d-none d-md-block col-12 text-start h2-text">Make stuff <span className='text-custom-2'>together</span>.</Col>
                        <Col className="col-md-12 d-md-none text-center h2-text p-5">Find local <span className='text-custom-1'>creatives and Make stuff <span className='text-custom-2'>together</span>.</span></Col>
                        
                        <Col xs={12} sm={12} md={10} lg={8} className="pe-0">                            
                        <div className='body-text text-secondary text-start mt-3 justify-text p-2'>Cife is a community of creatives to better access to resources, 
                        exposure & collaborations.<br />Create a profile, find local creatives and get what you need to make and sell art.<br />Until the
                        platform is fully ready and officially launched, sign-up for early access and get exclusive  perks.
                        </div>
                        <Col xs={12} className="text-start h5-text mb-3 mt-5 text-custom-1 mb-4 sign-up-text">Sign Up for Early Access</Col>
                        <Col xs={12} className="hero-signup">
                            <Form>                       
                                <Form.Control id="hero-form" type="email" placeholder="name@example.com" onChange={handleSignUpEmail} value={email} required />
                                <Button type="submit" id="hero-button" className="ms-2" variant="dark" size="md" onClick={handleSignUpShow}>Sign Up</Button>
                            </Form>                                                    
                        </Col>                        
                        </Col>
                        <Col xs={0} sm={0} md={2} lg={4}></Col>
                    </Row>
                </Col>
            </Row>
            <Row className='background-one m-0'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 50">
                    <path fill="#fafafa" fillOpacity="1" d="M0,96L1440,128L1440,320L0,320Z"></path>
                </svg>
                <Col xs={12} className="text-center mb-3 h2-text p-2">What can <span className='text-custom-1'>you</span> do on Cife<span className='text-custom-2'>?</span></Col>
                <Row className='m-0 p-0 mb-5'>
                    <Col className="d-none d-md-block col-md-1 p-0 col-lg-2"></Col>
                    <Col className="h4 fw-bold p-0 mt-sm-1 mt-md-1 mb-5" xs={12} md={5} lg={4}>
                        <div className='text-start h6-text pt-md-5 pt-1 justify-text'><span className='text-custom-2'>Discover</span> Local Artists and <span className='text-custom-1'>Collaborate.</span>
                        <span className='body-text text-secondary mt-4 d-block justify-text fw-light'>Explore local artist works and stories, from emerging artrists to well established artists.
                                Some on them are mentor's to Cife, here to advise and guide. Build your network with fellow artist in the scene.
                                Tell your story, hear their stories. You may find artists with similar ideas or whack ideas which could be the beginning
                                of a new collaboration</span>
                        </div>
                    </Col>
                    <Col className="p-0 image-two" xs={12} md={5} lg={4}><img src={imagetwo} alt="heroimage" /></Col>
                    <Col className="d-none d-md-block col-md-1 p-0 col-lg-2"></Col>
                </Row>
            </Row>
            <Row className='m-0 mt-4 mb-5'>                    
                <Col className="d-none d-md-block col-md-1 p-0 col-lg-2"></Col>
                <Col className="p-0 image-two d-none d-md-block" md={5} lg={4}><img src={imagethree} alt="heroimage" /></Col>
                <Col className="h4 fw-bold p-0 mt-sm-1" xs={12} md={5} lg={4}>
                    <div className='text-start h6-text mt-5'><span className='text-custom-1'>Showcase</span> your work & Reach <span className='text-custom-2'>New Audiences.</span>
                    <span className='body-text text-secondary mt-4 d-block justify-text fw-light'>Get one online dedicated online space to clearly display your art, along with your stories and 
                    articles. With a streamlined, clean format, you can easily curate a professional portfolio. Expand your market and reach beyond are little
                    island to link with people and find new and exciting opportinities across the globe</span>
                    </div>
                </Col>                        
                <Col className="d-none d-md-block col-md-1 p-0 col-lg-2"></Col>
                <Col className="p-0 image-two col-12 d-md-none"><img src={imagethree} alt="heroimage" /></Col>                    
            </Row>
            <Row className='background-one m-0'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 50">
                    <path fill="#fafafa" fillOpacity="1" d="M0,96L1440,128L1440,320L0,320Z"></path>
                </svg>
                <Row className='m-0 p-0 mb-5'>
                    <Col className="d-none d-md-block col-md-1 p-0 col-lg-2"></Col>
                    <Col className="h4 fw-bold p-0 mt-sm-1 mb-5" xs={12} md={5} lg={4}>
                        <div className='text-start h6-text pt-md-5 pt-1'>Find <span className='text-custom-2'>resources</span> you need to <span className='text-custom-1'>create </span>
                        and <span className='text-custom-1'> improve.</span>
                        <span className='body-text text-secondary mt-4 d-block justify-text fw-light'>Artist & hobbyist: find what you need to create art. From art supplies and music equipment to 
                        spaces, classes and guides. Find a directory of all the organizations and businesses providing what you seek and get discounts from our partners.<br /><br />
                        Organizations and businesses promote your products and services, and serve artist more directly.<br /><br />Ambassadors and sponsors connect more easily</span>
                        </div>
                    </Col>
                    <Col className="p-0 image-two" xs={12} md={5} lg={4}><img src={imagefour} alt="heroimage" /></Col>
                    <Col className="d-none d-md-block col-md-1 p-0 col-lg-2"></Col>
                </Row>
            </Row>        
            <Col xs={12} className="text-center mb-3 h2-text p-25 mt-5"><span className='text-custom-1'>Who</span> is using Cife<span className='text-custom-2'>?</span></Col>
            <Row className="mx-auto mb-5">
                <Col xs={0} sm={1} md={2}></Col>
                <Col xs={12} sm={10} md={8}>
                    <Row className='m-0'>
                        <Col xs={12} className="text-start h fw-bold mb-0"><span className='text-custom-1'>Artists</span></Col>
                        <Col xs={12} className='h1 p-0'>
                            <Row className='m-0'>
                                {renderArtists}
                            </Row>
                        </Col>
                    </Row>
                </Col>                
                <Col xs={0} sm={1} md={2}></Col>
            </Row>
            <Col className='background-three m-0' xs={12}><img src={curve} alt="heroimage" /></Col>
            <Row className='background-two m-0'>                
                <Col xs={12} className="text-center mb-3 h2-text p-2 text-light">Who We <span className='text-custom-1'>Serve</span></Col>
                <Row className='m-0'>
                    <Col className="d-none d-md-block col-md-4" xs={0} md={4}></Col>
                    <Col className="justify-text text-light" xs={12} md={4}>We serve all artists, from emerging to established artists, art business and 
                        organizations, as well as experts in the field. However, we prioritize the interests of artist from low-income who 
                        historically excluded background. We do this throughh outreach to find low-tech/low-income artist; and partnerships and
                        organizations who want to support <span className='text-custom-2'>underpriveledged artists</span></Col>
                    <Col className="d-none d-md-block col-md-4"xs={0} md={4}></Col>
                </Row>
                <Col xs={12} className="text-center mb-3 mt-5 h3 mb-5"><a className="h3 fw-bold hero-text text-light text-decoration-none hover my-auto" onClick={handleSignUpShow}>Sign Up for Early Access <FontAwesomeIcon className="text-light ms-3" icon={faArrowRight}/></a></Col>               
            </Row>
            <Row className='m-0 mb-sm-3 mb-md-5'>                  
                <Col className="d-none d-md-block col-md-2 p-0 col-lg-3 col-xl-4"></Col>
                <Col className="h4 fw-bold p-5 p-md-0 mt-sm-3 mt-md-5 contact-form mx-auto" xs={12} md={8} lg={6} xl={4}>
                    <ContactForm message={handleMessage}/>
                </Col>                        
                <Col className="d-none d-md-block col-md-2 p-0 col-lg-3 col-xl-4"></Col>                               
            </Row> 
            <SignUp show={showSignup} email={email} close={() => setShowSignup(false)} message={handleMessage}/>
        </div>        
    );
}

export default HomePage;