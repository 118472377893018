import React from 'react'
import './AboutPage.css'
import Hero from'../media/abouthero.png'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import profile from '../media/profile.png'
import { Button } from 'react-bootstrap'
import { useState, useEffect } from "react"
import female from '../media/female.jpg'
import male from  '../media/male.png'
import partners from '../media/partners.png'
import sponsorship from '../media/sponsorship.png'

function  About() {

    const [show, setShow] = useState(false);
    const [active, setActive] = useState('cife');
    const [scrollPosition, setScrollPosition] = useState(0);

    // const inputRef = useRef(null);
    useEffect(() => {
        let cife = document.getElementById('cife');       
        let team = document.getElementById('team-2');        
        let partner = document.getElementById('partners');
        let sponsor = document.getElementById('sponsor-2');
        let open = document.getElementById('open-2');
        if(cife.getBoundingClientRect().y < 0){
            setActive('cife')
        }
        if(team.getBoundingClientRect().y < 50){
            setActive('team')
        }
        if(partner.getBoundingClientRect().y < 50){
            setActive('partner')
        }
        if(sponsor.getBoundingClientRect().y < 50){
            setActive('sponsor')
        }
        if(open.getBoundingClientRect().y < 50){
            setActive('open')
        }
    });


    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
        if(position <= 470){
            setShow(false)
        }else{
            setShow(true)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    

    const handleActive = (event) => {
        console.log(event.target.id)
        setActive(event.target.id)
    }

    const sideMenu = () => {
        return (
        <Row className={show ? 'm-0 text-three text-start p-2 side-menu fade-in' : ''} >
        <Col className={active ==='cife' ? "m-2 active p-0": "m-2 not-active fw-bold text-dark p-0"} xs={12}><Button href="#cife" variant={active === 'cife' ? 'link text-decoration-none hover p-0 text-dark fw-bold' : 'link text-decoration-none hover p-0 text-secondary'} onClick={handleActive} id="cife">What is Cife</Button></Col>
        <Col className={active ==='team' ? "m-2 active p-0": "m-2 not-active fw-bold text-dark p-0"} xs={12}><Button href="#team-2" variant={active === 'team' ? 'link text-decoration-none hover p-0 text-dark fw-bold' : 'link text-decoration-none hover p-0 text-secondary'} onClick={handleActive} id="team">Team</Button></Col>
        <Col className={active ==='partner' ? "m-2 active p-0": "m-2 not-active fw-bold text-dark p-0"} xs={12}><Button href="#partners" variant={active === 'partner' ? 'link text-decoration-none hover p-0 text-dark fw-bold' : 'link text-decoration-none hover p-0 text-secondary'} onClick={handleActive} id="partner">Partners</Button></Col>
        <Col className={active ==='sponsor' ? "m-2 active p-0": "m-2 not-active fw-bold text-dark p-0"} xs={12}><Button href="#sponsor-2" variant={active === 'sponsor' ? 'link text-decoration-none hover p-0 text-dark fw-bold' : 'link text-decoration-none hover p-0 text-secondary'} onClick={handleActive} id="sponsor">Sponsor</Button></Col>
        <Col className={active === 'open' ? "m-2 active p-0": "m-2 not-active fw-bold text-dark p-0"} xs={12}><Button href="#open-2" variant={active === 'open' ? 'link text-decoration-none hover p-0 text-dark fw-bold' : 'link text-decoration-none hover p-0 text-secondary'} onClick={handleActive} id="open">Open</Button></Col>
        </Row>
        )
    }

    return (
        <div className='mt-2'>
            <div className='about-hero-image'>
                <img src={Hero} alt="hero"/>
            </div>
            <Row className='m-0 mt-5'>
                <Col xs={0} md={2}></Col>
                <Col xs={12} md={8} className='h2-text mt-5 text-black text-start' id="cife">What is Cife?</Col>
                <Col xs={0} md={2}></Col>
            </Row>
            <Row className='m-0'>
                <Col className='d-none d-md-block col-md-2'>
                    {show ? sideMenu() : <div></div>}
                </Col>
                <Col className="body-text text-secondary justify-text col-12 col-md-8  mt-5">
                    A cyph, short for cypher or cipher, is a free style dance jam where people create a circle and people take turns
                    dancing in the center. It has roots in hip hop culture and African tradition and is a defining elememnt of the urban
                    dance culture. Cyphs are characterized by creativity, high energy and camaraderie.
                    <div className='mt-5 mb-4 text-dark h7-text'>Who is an Artist?</div>
                    <div>We define artists/creatives very broadly, including design crafts and many more; and also include
                        hobbyists, or people who want to do creative stuff, jusr for fun. Not all hobbies have to monetized and 
                        people should not be pressured to fell otherwise.
                    </div>
                    <div className='mt-5 mb-4 text-dark h7-text'>Together in this</div>
                    <div>                  
                        Like dance cyphs which usually happen after dance competitions, Cife is, unlike other social media, not a competitive space, not where you compare numbers of followers & likes.  Cife is where you connect with one another & find what you need to create. Neither is it where you spend hours scrolling. We’d rather you spend that time creating. 
                        We’re all in this together, trying to open new possibilities through our imagination & creativity. Let’s make it easier, especially for those with less access to the resources to do it.
                    </div>
                    <div className='h2-text mt-5 text-black text-start top-margin' id="team-2">The Team</div>
                    <Row className="m-0 text-center mt-5">
                        <Col xs={12} sm={6}>
                            <Row className='m-0 mt-xs-2 mt-5'>
                                <Col xs={12} className="team-image"><img className="rounded-circle" src={female} alt="profile"/></Col>
                                <Col xs={12} className="m-2">Malina</Col>
                                <Col xs={12} className="m-2">Title</Col>
                                <Col xs={12} className="justify-text m-2">Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</Col>
                            </Row>                            
                        </Col>
                        <Col xs={12} sm={6}>
                            <Row className='m-0 mt-xs-2 mt-5'>
                                <Col xs={12} className="team-image"><img className="rounded-circle" src={male} alt="profile"/></Col>
                                <Col xs={12} className="m-2">Andrew</Col>
                                <Col xs={12} className="m-2">Title</Col>
                                <Col xs={12} className="justify-text m-2">Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</Col>
                            </Row>                            
                        </Col>
                        <Col xs={12} sm={6}>
                            <Row className='m-0 mt-xs-2 mt-5'>
                                <Col xs={12} className="team-image"><img className="rounded-circle" src={female} alt="profile"/></Col>
                                <Col xs={12} className="m-2">Naicha</Col>
                                <Col xs={12} className="m-2">Title</Col>
                                <Col xs={12} className="justify-text m-2">Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</Col>
                            </Row>                            
                        </Col>
                        <Col xs={12} sm={6}>
                            <Row className='m-0 mt-xs-2 mt-5'>
                                <Col xs={12} className="team-image"><img className="rounded-circle" src={male} alt="profile"/></Col>
                                <Col xs={12} className="m-2">Yannick</Col>
                                <Col xs={12} className="m-2">Title</Col>
                                <Col xs={12} className="justify-text m-2">Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</Col>
                            </Row>                            
                        </Col>
                    </Row>
                    <div className='h2-text mt-5 text-black text-start top-margin' id="partners">Partners</div>
                    <Row className="m-0 text-center mt-5">
                        <Col xs={6} sm={6} lg={3}>
                            <Row className='m-0 mt-xs-2 mt-md-5'>
                                <Col xs={12} className="partner-image"><img className="rounded-circle" src={partners} alt="profile"/></Col>
                            </Row>                            
                        </Col>
                        <Col xs={6} sm={6} lg={3}>
                            <Row className='m-0 mt-xs-2 mt-md-5'>
                                <Col xs={12} className="partner-image"><img className="rounded-circle" src={partners}  alt="profile"/></Col>
                            </Row>                            
                        </Col>
                        <Col xs={6} sm={6} lg={3}>
                            <Row className='m-0 mt-xs-2 mt-md-5'>
                                <Col xs={12} className="partner-image"><img className="rounded-circle" src={partners} alt="profile"/></Col>
                            </Row>                            
                        </Col>
                        <Col xs={6} sm={6} lg={3}>
                            <Row className='m-0 mt-xs-2 mt-md-5'>
                                <Col xs={12} className="partner-image"><img className="rounded-circle" src={partners} alt="profile"/></Col>
                            </Row>                            
                        </Col>
                    </Row>
                    <div className='h2-text mt-5 text-black text-start top-margin' id="sponsor-2">Sponsors</div>
                    <Row className="m-0 text-center mt-5">
                        <Col xs={6} sm={6} lg={3}>
                            <Row className='m-0 mt-xs-2 mt-md-5'>
                                <Col xs={12} className="partner-image"><img className="rounded-circle" src={sponsorship} alt="profile"/></Col>
                            </Row>                            
                        </Col>
                        <Col xs={6} sm={6} lg={3}>
                            <Row className='m-0 mt-xs-2 mt-md-5'>
                                <Col xs={12} className="partner-image"><img className="rounded-circle" src={sponsorship} alt="profile"/></Col>
                            </Row>                            
                        </Col>
                        <Col xs={6} sm={6} lg={3}>
                            <Row className='m-0 mt-xs-2 mt-md-5'>
                                <Col xs={12} className="partner-image"><img className="rounded-circle" src={sponsorship} alt="profile"/></Col>
                            </Row>                            
                        </Col>
                        <Col xs={6} sm={6} lg={3}>
                            <Row className='m-0 mt-xs-2 mt-md-5'>
                                <Col xs={12} className="partner-image"><img className="rounded-circle" src={sponsorship} alt="profile"/></Col>
                            </Row>                            
                        </Col>
                    </Row>
                    <div className='h2-text mt-5 text-black mb-4 text-start top-margin' id="open-2">Open Source</div>
                    <Col xs={12} className="body-text justify-text m-2 mb-5 mt-5">We are committed to transparency and a freer world. This is why our code is open-source.</Col>                                          
                </Col>
                <Col className='d-none d-md-block col-md-2'></Col>
            </Row>
        </div>
    );
}
export default About;