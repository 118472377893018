import React from 'react'
import './Artist.css'
import Hero from'../media/abouthero.png'
import ArtistLarge from './ArtistLarge'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {useState } from "react"
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'


function  Artist(props) {

    
    const [side, setSide] = useState(2);
    const [main, setMain] = useState(10);
    const [term, setTerm] = useState(null)
    const [tag, setTag] = useState('all')

    // console.log(setRadioSelection)
    const sideSpace = `d-none d-md-block col-md-${side}`
    const mainSpace = `text-start p-0 ps-md-3 col-12 col-md-${main}`

    const handleScreen = (num) => {
        if(side==='2'){
            setSide(1)
            setMain(11)
        }else{
            setSide(2)
            setMain(10)
        }
    }

    const handleTag = (event) => {
        setTag(event.target.id)
    } 

    const handleSearchTerm = (event) => {
        setTerm(event.target.value)
    }

    const setSelectedR = (message) => {
        setTag(message)
    }
    
    return (
        <div>
            <div className='artist-hero-image'>
                <img src={Hero} alt="hero"/>
            </div>
            <Row className='m-0 mt-5'>
                <Col className={sideSpace}></Col>
                <Col className={mainSpace}>
                    <InputGroup className="mb-5 search-bar">
                        <InputGroup.Text id="basic-addon1" className='border-end-0 bg-body border-grey border border-2'><FontAwesomeIcon className="text-custom-1 fw-bold" icon={faMagnifyingGlass} /></InputGroup.Text>
                        <Form.Control
                        onChange={handleSearchTerm}
                        className='border-start-0 border border-2 border-grey'
                        placeholder="Search by Artist"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        size='sm'
                        />
                    </InputGroup>
                    <Row className="m-0 mb-4">
                        <Col xs={12} className="m-0 p-0 discipline-tags">
                            <div className='h5 fw-bold d-inline-block text-four discipline-text d-none d-sm-inline-block'>Discipline</div>
                            <div className={tag==='all' ? 'border border-1 border-secondary p-0 d-tags rounded-4 d-inline-block ms-1 ms-md-3 tag-active btn' : 'border border-1 border-secondary  p-0 d-tags rounded-4 d-inline-block ms-1 ms-md-3 btn'} onClick={handleTag} id="all">All</div>
                            <div className={tag==='Visual Arts' ? 'border border-1 border-secondary  p-0 d-tags rounded-4 d-inline-block ms-1 ms-md-3 tag-active btn pe-2 ps-2' : 'border border-1 border-secondary p-0 d-tags rounded-4 d-inline-block ms-1 ms-md-3 btn pe-2 ps-2'} onClick={handleTag} id="Visual Arts">Visual</div>
                            <div className={tag==='Dance' ? 'border border-1 border-secondary  p-0 d-tags rounded-4 d-inline-block ms-1 ms-md-3 tag-active btn pe-2 ps-2' : 'border border-1 border-secondary p-0 d-tags rounded-4 d-inline-block ms-1 ms-md-3 btn pe-2 ps-2'} onClick={handleTag} id="Dance">Dance</div>
                            <div className={tag==='Music' ? 'border border-1 border-secondary  p-0 d-tags rounded-4 d-inline-block ms-1 ms-md-3 tag-active btn pe-2 ps-2' : 'border border-1 border-secondary p-0 d-tags rounded-4 d-inline-block ms-1 ms-md-3 btn pe-2 ps-2'} onClick={handleTag} id="Music">Music</div>
                        </Col>
                    </Row>         
                </Col>
            </Row>
            <div><ArtistLarge handleClick={handleScreen} handleTerm={term} handleTag={tag} tags={setSelectedR}/></div>    
        </div>
    );
}
export default Artist;